/* Global font styling */
body {
    font-family: 'Helvetica', 'Arial', sans-serif;
}

/* Page container */
.contact-page {
    background-color: #000000; /* Black background */
    color: #fff; /* White text */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure footer stays at the bottom */
}

/* Contact container for content alignment */
.contact-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align content to the left */
    text-align: left; /* Left-align text */
    padding: 4rem;
    width: 100%;
}

.contact-container h1 {
    font-size: 2.5rem;
    margin-bottom: 0.2rem; /* Reduced space below header */
}

.contact-container p {
    font-size: 1.2rem;
    margin-top: 0.1rem; /* Slightly reduced top margin for paragraph */
}

/* Thank-you confirmation message */
.thank-you-message {
    text-align: center;
    margin-top: 2rem;
}
.thank-you-message h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}
.thank-you-message p {
    font-size: 1.2rem;
}

/* Contact form styles */
.contact-form {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    width: 100%;
    max-width: 500px; /* Limit max-width */
}

/* Form layout for two-column rows */
.contact-form-row {
    display: flex;
    justify-content: space-between; /* Ensure equal space between first and last name */
    gap: 3rem; /* Space between the two fields */
    width: 100%; /* Full width of the form container */
}

/* Apply two-column layout to groups inside a row */
.contact-form-row .contact-form-group {
    flex-basis: 48%; /* Each field takes 48% of the row width */
}

/* Single field groups */
.contact-form-group {
    width: 100%; /* Full width for groups not in a row */
    display: flex;
    flex-direction: column;
    margin-bottom: 1.2rem;
}

/* Extra space for the captcha group */
.captcha-group {
    margin-bottom: 2rem;
}

/* Extra spacing between captcha question and input */
.captcha-group label {
    margin-bottom: 1rem;
}

/* Input fields with placeholders */
.contact-form-group input,
.contact-form-group textarea {
    width: 100%; /* Ensure inputs take up full width of their container */
    padding: 1rem; /* Add padding for a box-like feel */
    border-radius: 0.2rem;
    border: none; /* Remove borders */
    background-color: #1a1a1a; /* Dark background for the input fields */
    color: #fff; /* White text inside the input fields */
    font-size: 1rem;
    font-family: inherit; /* Ensure same font as defined globally */
    transition: background-color 0.3s, color 0.3s;
}

/* Placeholder styling */
.contact-form-group input::placeholder,
.contact-form-group textarea::placeholder {
    color: #aaa; /* Light gray for placeholder text */
    font-size: 1rem;
}

/* Input focus styling */
.contact-form-group input:focus,
.contact-form-group textarea:focus {
    outline: none; /* Remove default outline */
    background-color: #1a1a1a; /* Keep dark background */
    color: #fff; /* White text on focus */
}

/* Remove text highlighting on input when selected */
.contact-form-group input::selection,
.contact-form-group textarea::selection {
    background: #444; /* Darker gray background for selected text */
    color: #fff; /* White text on selection */
}

/* Submit button */
.contact-submit-button {
    background-color: #4a8ad9;
    color: #fff;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 0.2rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s;
    width: 120px; /* Change this value to whatever width you prefer */
    text-align: center; /* Center the text inside the button */
}

.contact-submit-button:hover {
    background-color: #367bb5;
}
