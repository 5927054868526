/* Admin Rental Status Styles */

.rental-status-container {
    background-color: #fff;
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    color: #333;
    margin: 2rem 0;
  }
  
  .rental-status-container h1 {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
    color: #4a8ad9;
    text-align: center;
  }
  
  /* Responsive table container */
  .table-responsive {
    overflow-x: auto;
  }
  
  /* Rental Status Table */
  .rental-status-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9rem;
  }
  
  .rental-status-table th,
  .rental-status-table td {
    padding: 0.75rem;
    border: 1px solid #eee;
    text-align: left;
  }
  
  .rental-status-table th {
    background-color: #f5f5f5;
    font-weight: bold;
    color: #333;
  }
  
  .rental-status-table tbody tr:nth-child(even) {
    background-color: #fafafa;
  }
  
  .rental-status-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Loading and error messages */
  .loading,
  .error {
    text-align: center;
    font-size: 1rem;
    color: #777;
    margin: 1rem 0;
  }
  