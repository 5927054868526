/* General container styling */
.device-setup-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the top */
  min-height: 100vh; /* Full viewport height */
  width: 100%;
  background-color: #000000; /* Black background */
  padding: 20px;
  box-sizing: border-box;
}

/* Box containing the setup instructions */
.device-setup-box {
  display: flex;
  flex-direction: column;
  background-color: #000000;
  border-radius: 8px;
  width: 100%;
  max-width: 1000px;
  padding: 20px;
  box-sizing: border-box;
}

/* Center the header */
.device-setup-header {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #4a8ad9;
}

/* Content section centered on the page */
.device-setup-content {
  width: 100%;
  display: block;
  margin: 0 auto;
}

/* Text column with left-aligned text */
.device-setup-text {
  padding: 20px;
  text-align: left; /* Keeps the internal text left aligned */
  color: #ffffff;
}

/* Center the introduction text */
.device-setup-intro {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #ffffff;
}

/* Center the support button */
.device-setup-support {
  text-align: center;
  margin-top: 20px;
  width: 100%;
}

.device-setup-text ol {
  margin-top: 20px;
  padding-left: 20px;
  color: #ffffff;
}

.device-setup-text ol li {
  margin-bottom: 15px;
  font-size: 1.2rem;
}

.device-setup-text p {
  margin-top: 20px;
  font-size: 1rem;
  color: #ffffff;
}

.device-setup-text a.support-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4a8ad9;
  color: #ffffff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.device-setup-text a.support-link:hover {
  background-color: #0056b3;
}

/* Removed the images styles since the images block is no longer used */
