/* Admin Client Manager Styles */

.client-manager {
    background-color: #fff;
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    color: #333;
    font-family: 'Roboto', sans-serif;
    margin: 2rem 0;
  }
  
  .client-manager h3 {
    margin-bottom: 1rem;
    font-size: 1.75rem;
    color: #4a8ad9;
  }
  
  .message {
    margin-bottom: 1rem;
    padding: 0.5rem;
    color: #fff;
    background-color: #4a8ad9;
    border-radius: 4px;
  }
  
  .add-client {
    margin-bottom: 2rem;
  }
  
  .add-client h4 {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .add-client button {
    padding: 0.75rem 1.5rem;
    background-color: #4a8ad9;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .add-client button:hover {
    background-color: #367bb5;
  }
  
  .client-list h4 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .client-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .client-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    border-bottom: 1px solid #eee;
  }
  
  .client-list li:last-child {
    border-bottom: none;
  }
  
  .client-list li span {
    font-size: 1rem;
    color: #333;
  }
  
  .client-list li button {
    padding: 0.5rem 1rem;
    background-color: #d9534f;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .client-list li button:hover {
    background-color: #c9302c;
  }
  