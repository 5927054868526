/* Global Box Sizing */
*, *::before, *::after {
  box-sizing: border-box;
}

/* General Styles */
html, body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
}
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.landing-page {
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  margin: 0;
  padding: 0;
  background-color: #000; /* Black background */
}

/* Ensure all images within landing-page do not exceed their container */
.landing-page img {
  max-width: 100%;
  height: auto;
}

/* Hero Section */
.hero-section {
  background-image: url('../img/bg.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  color: #ffffff;
  text-align: center;
  padding: clamp(3rem, 10vw, 6rem) 2%;
}
.hero-section::before {
  content: '';
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.hero-content {
  position: relative;
  z-index: 1;
}
.title-company-logo {
  width: 80%;
  max-width: 1000px;
  height: auto;
  margin-bottom: 1rem;
}
.hero-section p {
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  max-width: 600px;
  margin: 0 auto 2rem;
}
.cta-button {
  padding: 0.75rem 1.5rem;
  background-color: #4a8ad9;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}
.cta-button:hover {
  background-color: #367bb5;
}

/* Features Section */
.features-section {
  padding: 4rem 2%;
  text-align: center;
  background-color: #000000;
}
.features-section h2 {
  font-size: clamp(1.5rem, 4vw, 2rem);
  margin-bottom: 3rem;
  color: #ffffff;
}
.features-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}
.feature-item {
  background-color: #ffffff;
  border: 1px solid #333;
  border-radius: 10px;
  padding: 2rem;
  flex: 1 1 300px;
  max-width: 350px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.feature-item img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 1.5rem;
}
.feature-item h3 {
  font-size: clamp(1rem, 2.5vw, 1.25rem);
  margin-bottom: 1rem;
  color: #4a8ad9;
}
.feature-item p {
  font-size: clamp(0.9rem, 2vw, 1rem);
  color: #000000;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}

/* Split Section */
.split-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4rem 2%;
  background-color: #4a8ad9;
}
.split-content {
  flex: 1 1 100%;
  padding-right: 2%;
  margin-bottom: 2rem;
}
.split-content h2 {
  font-size: clamp(1.5rem, 4vw, 2rem);
  margin-bottom: 1.5rem;
  color: #ffffff;
}
.split-content p {
  font-size: clamp(1rem, 2.5vw, 1rem);
  color: #ffffff;
  margin-bottom: 2rem;
}
/* Limit the container for the business photo */
.split-image {
  flex: 1 1 100%;
  text-align: center;
  overflow: hidden;
  margin-bottom: 2rem;
  max-width: 750px;  /* New limit for container width */
  margin-left: auto;
  margin-right: auto;
}
.split-image img {
  width: 70%;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: contain;
}

/* Cards Section */
.cards-section {
  padding: 4rem 2%;
  text-align: center;
  background-color: #000000;
}
.cards-section h2 {
  font-size: clamp(1.5rem, 4vw, 2rem);
  margin-bottom: 3rem;
  color: #ffffff;
}
.cards-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}
.card-item {
  background-color: #ffffff;
  border: 1px solid #333;
  border-radius: 10px;
  padding: 2rem;
  flex: 1 1 250px;
  max-width: 300px;
  text-align: center;
}
.card-item img {
  width: 60%;
  height: auto;
  margin-bottom: 1rem;
}
.card-item h3 {
  font-size: clamp(1rem, 2.5vw, 1.25rem);
  margin-bottom: 1rem;
  color: #4a8ad9;
}
.card-item p {
  font-size: clamp(0.9rem, 2vw, 1rem);
  color: #000000;
}

/* CTA Section */
.cta-section {
  background-color: #4a8ad9;
  color: #fff;
  padding: 4rem 2%;
  text-align: center;
}
.cta-section h2 {
  font-size: clamp(1.5rem, 4vw, 2rem);
  margin-bottom: 1rem;
}
.cta-section p {
  font-size: clamp(1rem, 2.5vw, 1.25rem);
  margin-bottom: 2rem;
}
.cta-section .cta-button {
  background-color: #fff;
  color: #4a8ad9;
  font-weight: bold;
}
.cta-section .cta-button:hover {
  background-color: #eaeaea;
}
