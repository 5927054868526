/* FAQ Page Container */
.faq-page {
    background-color: #000000; /* Black background */
    color: #fff; /* White text */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure footer stays at the bottom */
}

/* FAQ Container for content alignment */
.faq-container {
    padding: 4rem;
    max-width: 800px; /* Contain the text within 800px */
    width: 100%;
    text-align: left; /* Left-align text */
}

/* Text Formatting */
.faq-container h1 {
    font-size: 2.5rem;
    margin-bottom: 4rem;
}

.faq-container h2 {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
}

.faq-container p {
    font-size: 1rem;
    margin-bottom: 3rem;
}
