/* Admin Device Manager Styles */

.device-manager {
  background-color: #fff;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  color: #333;
  font-family: 'Roboto', sans-serif;
  margin: 2rem 0;
}

.device-manager h3 {
  margin-bottom: 1rem;
  font-size: 1.75rem;
  color: #4a8ad9;
}

.message {
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #4a8ad9;
  color: #fff;
  border-radius: 4px;
}

.search-device {
  margin-bottom: 2rem;
}

.search-device h4 {
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
}

.search-device input {
  width: 60%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 1rem;
  font-size: 1rem;
}

.search-device button {
  padding: 0.75rem 1.5rem;
  background-color: #4a8ad9;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-device button:hover {
  background-color: #367bb5;
}

.search-result {
  margin-bottom: 2rem;
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.search-result h4 {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  color: #333;
}

.device-list h4 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.device-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.device-list li {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.device-list li:last-child {
  border-bottom: none;
}

.device-list p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
}
