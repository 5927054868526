/* Admin Product Manager Styles */

.admin-product-manager {
    background-color: #fff;
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    color: #333;
    margin: 2rem 0;
  }
  
  .admin-product-manager h1 {
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
    color: #4a8ad9;
  }
  
  .client-select {
    margin-bottom: 2rem;
  }
  
  .client-select label {
    display: block;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .client-select select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .products-section h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .loading {
    font-size: 1rem;
    color: #777;
  }
  
  .product-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .product-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #eee;
  }
  
  .product-item:last-child {
    border-bottom: none;
  }
  
  .product-details {
    flex: 1;
  }
  
  .product-details p {
    margin: 0.25rem 0;
    font-size: 1rem;
  }
  
  .product-image {
    margin-left: 1rem;
  }
  
  .product-image img {
    max-width: 100px;
    height: auto;
    border-radius: 4px;
    object-fit: contain;
  }
  