/* Admin Dashboard Styles */

.admin-dashboard {
    background-color: #f5f5f5;
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
    color: #333;
  }
  
  .admin-header {
    background-color: #333;
    color: #fff;
    padding: 1rem;
    text-align: center;
  }
  
  .admin-nav {
    background-color: #444;
    padding: 0.75rem;
  }
  
  .admin-nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 0;
    padding: 0;
  }
  
  .admin-nav ul li a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s;
  }
  
  .admin-nav ul li a:hover {
    color: #4a8ad9;
  }
  
  .admin-content {
    padding: 2rem;
  }
  