/* Header Styles using Grid Layout with 2 Columns */
.landing-header {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);  /* Left: logo, Right: nav+login */
    column-gap: clamp(1rem, 2vw, 2rem);  /* Fluid gap between logo and right section */
    align-items: center;
    padding: 1rem 2%;
    background-color: #000000;
    border-bottom: 1px solid #333;
    position: sticky;
    top: 0;
    z-index: 100;
    max-width: 100vw; /* Ensure header stays within viewport */
    overflow-x: hidden;
  }
  
  .logo {
    width: clamp(40px, 12vw, 120px); /* Responsive logo size */
    height: auto;
  }
  
  /* Header-right contains both navigation and login button */
  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: clamp(0.5rem, 2vw, 2rem);  /* Fluid gap so items have room to shrink */
    flex-shrink: 1;
    min-width: 0;  /* Allow children to shrink as needed */
  }
  
  /* Navigation inside header-right */
  .header-right nav {
    flex-shrink: 1;
    min-width: 0;
  }
  .header-right nav ul {
    list-style: none;
    display: flex;
    gap: 1rem;
    margin: 0;
    padding: 0;
  }
  .header-right nav ul li a {
    text-decoration: none;
    color: #ffffff;
    font-weight: 500;
    transition: color 0.3s;
    font-size: clamp(0.8rem, 2vw, 1rem);
  }
  .header-right nav ul li a:hover {
    color: #ffffff;
  }
  
  /* Login button now part of header-right */
  .login-button-horizontal {
    width: clamp(6em, 10vw, 8em);
    height: clamp(2em, 4vw, 2.5em);
    border: 2px ridge #4a8ad9;
    outline: none;
    background-color: transparent;
    color: #ffffff;
    border-radius: 0.3em;
    font-size: clamp(12px, 2vw, 14px);
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
    flex-shrink: 1; /* Allow the button to shrink if needed */
  }
  .login-button-horizontal:hover {
    box-shadow: inset 0px 0px 20px #367bb5;
  }
  
  /* Hide the logo entirely on small screens when nav links start to overlap */
  @media (max-width: 350px) {
    .logo {
      display: none;
    }
    .landing-header {
      grid-template-columns: 1fr; /* Only nav+login remains */
    }
  }
  
  /* Footer Styles */
  .landing-footer {
    background-color: #000;
    color: #fff;
    padding: 2rem 2%;
  }
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  .landing-footer p {
    margin-bottom: 1rem;
  }
  .landing-footer nav ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 0;
    margin: 0;
  }
  .landing-footer nav ul li a {
    color: #fff;
    text-decoration: none;
    font-size: clamp(0.8rem, 2vw, 0.9rem);
  }
  .landing-footer nav ul li a:hover {
    color: #4a8ad9;
  }
  